import BigNumber from 'bignumber.js'
import { ChainId } from '@pancakeswap/sdk'
import { BLOCKS_PER_YEAR } from 'config'
import lpAprs56 from 'config/constants/lpAprs/56.json'
import lpAprs1 from 'config/constants/lpAprs/1.json'
import { PoolCategory } from '../config/constants/types'

const getLpApr = (chainId: number) => {
  switch (chainId) {
    case ChainId.BSC:
      return lpAprs56
    case ChainId.ETHEREUM:
      return lpAprs1
    default:
      return {}
  }
}

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number,
): number => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

const ONE_YEAR_BLOCK = 630720
const ONE_YEAR_SECOND = 31536000

export const getBankApr = (
  poolCategory:PoolCategory,
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  tokenPerBlock: number, // tokenPerBlock 总奖励
  staked:number,
  // totalReward:number,
  totalStaked: number,
  minStakeToken: number,
  startBlock: number,
  endBlock: number,
): number => {
  // console.log('apr',stakingTokenPrice,rewardTokenPrice, tokenPerBlock,staked,totalStaked,minStakeToken)

  if(poolCategory===PoolCategory.STAKINGPOOLV2){
    if (totalStaked)
      return tokenPerBlock / totalStaked * (stakingTokenPrice / rewardTokenPrice) * 100 * (ONE_YEAR_SECOND/(endBlock-startBlock))
    else return tokenPerBlock / minStakeToken * (stakingTokenPrice / rewardTokenPrice) * 100
  }
  // 每个区块中的奖励数量 / 池中总的质押数量 * 最少质押数量 * 每年区块数量 * 质押币价格 / 奖励币价格
  // big number 计算有误
  // const apr = (new BigNumber(tokenPerBlock).div(new BigNumber(totalStaked)).times(new BigNumber(minStakeToken)).times(new BigNumber(stakingTokenPrice).div(new BigNumber(rewardTokenPrice))).times(BLOCKS_PER_YEAR) ).times(100)
  // number 计算有误
  // const apr = (tokenPerBlock / totalStaked) * minStakeToken * (stakingTokenPrice / rewardTokenPrice) * BLOCKS_PER_YEAR * 100
  // return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()

  // 总奖励（一年） * （用户的质押/总的质押数量）/ 用户的质押 * （质押币价格 / 奖励币价格）* 100
  if (totalStaked)
  return tokenPerBlock / totalStaked * (stakingTokenPrice / rewardTokenPrice) * 100 * (ONE_YEAR_BLOCK/(endBlock-startBlock))
  // tokenPerBlock * (staked / totalStaked)/staked * (stakingTokenPrice / rewardTokenPrice) * 100
  else return tokenPerBlock / minStakeToken * (stakingTokenPrice / rewardTokenPrice) * 100
}

/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param cakePriceUsd Cake price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @param farmAddress Farm Address
 * @returns Farm Apr
 */
export const getFarmApr = (
  chainId: number,
  poolWeight: BigNumber,
  cakePriceUsd: BigNumber,
  poolLiquidityUsd: BigNumber,
  farmAddress: string,
  regularCakePerBlock: number,
): { cakeRewardsApr: number; lpRewardsApr: number } => {
  const yearlyCakeRewardAllocation = poolWeight
    ? poolWeight.times(BLOCKS_PER_YEAR * regularCakePerBlock)
    : new BigNumber(NaN)
  const cakeRewardsApr = yearlyCakeRewardAllocation.times(cakePriceUsd).div(poolLiquidityUsd).times(100)
  let cakeRewardsAprAsNumber = null
  if (!cakeRewardsApr.isNaN() && cakeRewardsApr.isFinite()) {
    cakeRewardsAprAsNumber = cakeRewardsApr.toNumber()
  }
  const lpRewardsApr = (getLpApr(chainId)[farmAddress?.toLowerCase()] || getLpApr(chainId)[farmAddress]) ?? 0 // can get both checksummed or lowercase
  return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr }
}

export default null
