import { FAST_INTERVAL, SLOW_INTERVAL } from 'config/constants'
// eslint-disable-next-line camelcase
import useSWR, { useSWRConfig, unstable_serialize } from 'swr'
import useSWRImmutable from 'swr/immutable'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useProvider } from 'wagmi'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { zkChains } from "../../config/chains";
import MulticallABI from "../../config/abi/Multicall.json";
import { readContract } from '@wagmi/core'
import { multicallAddresses } from "@pancakeswap/multicall";
import { BigNumber } from '@ethersproject/bignumber'

// todo:peta 刷新过于频繁 ,zk改为50秒
const REFRESH_BLOCK_INTERVAL = 60000 // 6000

export const fetchL2BatchSyncBlockNumber = async (chainId)=>{
  const blockNumber = await readContract({
      chainId: chainId,
      address: multicallAddresses[chainId],
      abi: MulticallABI,
      functionName: 'getBlockNumber',
    }
  )
  const zkBlockNumber = blockNumber? BigNumber.from(blockNumber).toNumber() :0
  console.log('getBlockNumber','zkBlockNumber',zkBlockNumber)
  return zkBlockNumber
}

export const usePollBlockNumber = () => {
  const { cache, mutate } = useSWRConfig()
  const { chainId, provider } = useActiveWeb3React()

  const { data } = useSWR(
    chainId && ['blockNumberFetcher', chainId],
    async () => {
      const blockNumber = await provider.getBlockNumber()
      mutate(['blockNumber', chainId], blockNumber)
      if (!cache.get(unstable_serialize(['initialBlockNumber', chainId]))?.data) {
        mutate(['initialBlockNumber', chainId], blockNumber)
      }
      return blockNumber
    },
    {
      refreshInterval: REFRESH_BLOCK_INTERVAL,
    },
  )

  const { data:data2 } = useSWR(
    chainId && ['l2BatchSyncBlockNumberFetcher', chainId],
    async () => {
      const l2BatchSyncBlockNumber = await fetchL2BatchSyncBlockNumber(chainId)
      mutate(['l2BatchSyncBlockNumber', chainId], BigNumber.from(l2BatchSyncBlockNumber).toNumber())
      if (!cache.get(unstable_serialize(['initialL2BatchSyncBlockNumber', chainId]))?.data) {
        mutate(['initialL2BatchSyncBlockNumber', chainId], l2BatchSyncBlockNumber)
      }
      return l2BatchSyncBlockNumber
    },
    {
      refreshInterval: REFRESH_BLOCK_INTERVAL,
    },
  )

  useSWR(
    chainId && [FAST_INTERVAL, 'blockNumber', chainId],
    async () => {
      return data
    },
    {
      refreshInterval: FAST_INTERVAL,
    },
  )

  useSWR(
    chainId && [SLOW_INTERVAL, 'blockNumber', chainId],
    async () => {
      return data
    },
    {
      refreshInterval: SLOW_INTERVAL,
    },
  )

  useSWR(
    chainId && [FAST_INTERVAL, 'l2BatchSyncBlockNumber', chainId],
    async () => {
      return data2
    },
    {
      refreshInterval: FAST_INTERVAL,
    },
  )

  useSWR(
    chainId && [SLOW_INTERVAL, 'l2BatchSyncBlockNumber', chainId],
    async () => {
      return data2
    },
    {
      refreshInterval: SLOW_INTERVAL,
    },
  )
}

export const useCurrentBlock = (): number => {
  const { chainId } = useActiveChainId()
  const { data: currentBlock = 0 } = useSWRImmutable(['blockNumber', chainId])
  return currentBlock
}

export const useCurrentBlockOrL2SyncBlock = (): number => {
  const { chainId } = useActiveChainId()
  const { data: currentBlock = 0 } = useSWRImmutable([zkChains.includes(chainId)?'l2BatchSyncBlockNumber':'blockNumber', chainId])
  return currentBlock
}

export const useChainCurrentBlock = (chainId: number): number => {
  const { chainId: activeChainId } = useActiveWeb3React()
  const provider = useProvider({ chainId:activeChainId })
  const { data: currentBlock = 0 } = useSWR(
    chainId ? (activeChainId === chainId ? ['blockNumber', chainId] : ['chainBlockNumber', chainId]) : null,
    activeChainId !== chainId
      ? async () => {
          console.log('getBlockNumber','useChainCurrentBlock')
          const blockNumber = await provider.getBlockNumber()
          return blockNumber
        }
      : undefined,
    activeChainId !== chainId
      ? {
          refreshInterval: REFRESH_BLOCK_INTERVAL,
        }
      : undefined,
  )
  return currentBlock
}

export const useCurrentBlockTimestamp = () => {
  const { chainId } = useActiveWeb3React()
  const { data: currentTimestamp=0  } = useSWR(['currentBlockTimeStamp', chainId],
    async ()=>{
      return await fetchCurrentBlockTimestamp(chainId)
    },
    {
      refreshInterval: REFRESH_BLOCK_INTERVAL,
    }
  )
  return currentTimestamp
}

export const fetchCurrentBlockTimestamp = async (chainId)=>{
  const timestamp = await readContract({
      chainId: chainId,
      address: multicallAddresses[chainId],
      abi: MulticallABI,
      functionName: 'getCurrentBlockTimestamp',
    }
  )
  const currentBlockTime = timestamp? BigNumber.from(timestamp).toNumber() :0
  console.log('getCurrentBlockTimestamp',currentBlockTime)
  return currentBlockTime
}

export const useInitialBlock = (): number => {
  const { chainId } = useActiveChainId()
  const { data: initialBlock = 0 } = useSWRImmutable([zkChains.includes(chainId)?'initialL2BatchSyncBlockNumber':'initialBlockNumber', chainId])
  return initialBlock
}
