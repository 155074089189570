import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Petaverse',
  defaultTitle: 'Petaverse',
  description:
    'We offer a Metaverse that combines elements of pet socialization, gaming, virtual assets, DeFi，and more, to help pet owners better care for their pets and enhance their interaction with them.',
  // twitter: {
  //   cardType: 'summary_large_image',
  //   handle: '@PancakeSwap',
  //   site: '@PancakeSwap',
  // },
  // openGraph: {
  //   title: '🥞 PancakeSwap - A next evolution DeFi exchange on BNB Smart Chain (BSC)',
  //   description:
  //     'The most popular AMM on BSC by user count! Earn CAKE through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by PancakeSwap), NFTs, and more, on a platform you can trust.',
  //   images: [{ url: 'https://assets.pancakeswap.finance/web/og/hero.jpg' }],
  // },
}
