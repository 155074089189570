import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  DropdownMenuItems,
  HamburgerIcon,
  HotIcon,
  LightBulbIcon,
  PredictionsIcon,
  PrizeIcon,
  PresentCheckIcon,
  HamburgerCloseIcon,
  StarFillIcon,
  CommunityFilledIcon,
  InfoFilledIcon,
  InfoIcon,
  CheckmarkCircleFillIcon,
  ResourcesFilledIcon
} from "@pancakeswap/uikit";
import { ContextApi } from '@pancakeswap/localization'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { gumballBaseUrl } from 'views/Gumball/constants'
import { getPerpetualUrl } from 'utils/getPerpetualUrl'
import { SUPPORT_ONLY_BSC } from 'config/constants/supportChains'
import Hamburger from "@pancakeswap/uikit/src/components/Svg/Icons/Hamburger";
import StarFill from "@pancakeswap/uikit/src/components/Svg/Icons/StarFill";

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap'||'liquidity',
      // showItemsOnMobile: false,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
        {
          label: t('RWA Marketplace'),
          href: 'https://petaverse.space/rwa',
          target: '_blank',
          comingSoon: true,
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Earn'),
      href: '/pools'||'/miracle',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      items: [
        {
          label: t('Farms'),
          href: '/miracle',
          // href: '/?modal=miracle',
        },
        {
          label: t('Pools'),
          href: '/pools',
          // supportChainIds: SUPPORT_ONLY_BSC,
        },
        {
          label: t('Jackpot'),
          href: 'https://petaverse.space/jackpot',
          target: '_blank',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('NFT Rewards'),
          href: 'https://petaverse.store/#/staking',
          comingSoon: true,
          target: '_blank',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('NFT'),
      icon: NftIcon,
      fillIcon: NftFillIcon,
      items: [
        {
          label: t('Exchange'),
          href: `${gumballBaseUrl}#/swap`,
          type: DropdownMenuItemType.EXTERNAL_LINK,
          target: '_blank',
        },
        {
          label: t('Gumball'),
          href: `${gumballBaseUrl}/#/gachaMachine`,
          type: DropdownMenuItemType.EXTERNAL_LINK,
          target: '_blank',
        },
        {
          label: t('NFT Marketplace'),
          href: `${gumballBaseUrl}/#/list`,
          type: DropdownMenuItemType.EXTERNAL_LINK,
          target: '_blank',
        },
      ],
    },
    {
      label: t('Game'),
      icon: TrophyIcon,
      fillIcon: TrophyFillIcon,
      items: [
        {
          label: t('Petrip'),
          href: `https://petaverse.space/petrip`,
          type: DropdownMenuItemType.EXTERNAL_LINK,
          target: '_blank',
        },
        {
          label: t('Others'),
          href: '/game/others',
          comingSoon: true,
          type: DropdownMenuItemType.EXTERNAL_LINK,
          target: '_blank',
        },
        {
          label: 'PCN '+ t('Whitelist'),
          href: `https://petaverse.space/pcn`,
          type: DropdownMenuItemType.EXTERNAL_LINK,
          target: '_blank',
        },
      ],
    },
    {
      label: t('Dapp'),
      icon: PredictionsIcon,
      fillIcon: PredictionsIcon,
      items: [
        {
          label: t('Peta'),
          href: `https://petaverse.space/peta`,
          target: '_blank',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: 'PNS '+ t('Registration'),
          href: `https://pns.pet`,
          target: '_blank',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: 'PNS ' + t('Marketplace'),
          href: `${gumballBaseUrl}/#/pnslist`,
          target: '_blank',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t('NFT Launchpad'),
          href: `${gumballBaseUrl}`,
          type: DropdownMenuItemType.EXTERNAL_LINK,
          target: '_blank',
        },
      ],
    },
    {
      label: t('More'),
      href: '/peta',
      icon: InfoIcon,
      fillIcon: InfoFilledIcon,
      hideSubNav: true,
      items: [
        {
          label: t('About Peta'),
          href: '/peta',
        },
        {
          label: t('Roadmap'),
          href: '/peta/roadmap',
        },
        {
          label: t('The Lab'),
          href: '/peta/lab',
        },
        /*{
          label: t('Info'),
          // href: '/info',
        },
        {
          label: t('Leaderboard'),
          href: '/teams',
          supportChainIds: SUPPORT_ONLY_BSC,
          image: '/images/decorations/leaderboard.png',
        },*/
        {
          type: DropdownMenuItemType.DIVIDER,
        },
        {
          label: t('Whitepaper'),
          href: 'https://offiacls-organization.gitbook.io/petaverse/',
          type: DropdownMenuItemType.EXTERNAL_LINK,
          target: '_blank',
        },
        {
          label: t('Dao'),
          href: 'https://offiacls-organization.gitbook.io/petaverse/dao',
          type: DropdownMenuItemType.EXTERNAL_LINK,
          target: '_blank',
          comingSoon: true,
        },
        /*{
          label: t('METAVERSE GATE'),
          href: '/meta',
          target: '_blank',
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },*/
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config
