import { ChainId, JSBI, Percent, Token, WNATIVE } from '@pancakeswap/sdk'
import { BigNumber } from '@ethersproject/bignumber'
import {
  bscTokens,
  bscTestnetTokens,
  USDC,
  USDT,
  BUSD,
  WBTC_ETH,
  PETA,
  CBS_POLYGON,
  CBS_POLYGON_TESTNET,
  TEST_ZKSYNC_TESTNET,
  TEST_POLYGON_TESTNET,
  USDT_POLYGON,
  PTC_ZKSYNC,
  PTP_ZKSYNC,
} from "@pancakeswap/tokens";
import { ChainMap, ChainTokenList } from './types'
import { WrappedTokenInfo } from '@pancakeswap/token-lists'

export const ROUTER_ADDRESS: ChainMap<string> = {
  [ChainId.ETHEREUM]: '0x1BC43Dd6E42c423a20E82158c9A718aD67Bb19FA',
  [ChainId.GOERLI]: '0x08c26934d0f031def9bca70981cdb3c4c9708337',
  [ChainId.ZKSYNC]: '0x0d519E5a74321680e1C3D3E3Ebe8a5536Ba9E0D9',
  [ChainId.ZKSYNC_TESTNET]: '0x796D2a451ed9548d4B39667820a9160cE2b85be6',
  [ChainId.BSC]: '0x086CEf84B28BAf3269aFA74953eC7fCcf83E8d3F',
  [ChainId.BSC_TESTNET]: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
  [ChainId.DEV_ETH]: '0xB8D7CA6a3253C418e52087693ca688d3257D70D1',
  [ChainId.DEV_ZK]: '0xA51d98F8EfF2D9899C70Be83A9839Db62E6EBbD0',
  [ChainId.POLYGON_TESTNET]: '0x82c2432699f32a8e4c9db09d4a3d17015d275d27',
  [ChainId.POLYGON]: '0xD6071732f7f50C7e334bbF1720175E31A6Ac7a6A',
  [ChainId.FIL_TESTNET]: '0x8080D39407735692130114286f133DF1AA8F788e',
  [ChainId.LINEA_TESTNET]: '0xf3EBAF8Cc4E03352c97710b5ed98c35Cf4E7E4E0',
  [ChainId.FIL]: '0x2675e5f5ab2a28ea145db4d4cc40738d0e7dca41',
  [ChainId.LINEA]: '0x0000000000000000000000000000000000000000',
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.ETHEREUM]: [WNATIVE[ChainId.ETHEREUM], PETA[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM], WBTC_ETH],
  [ChainId.GOERLI]: [WNATIVE[ChainId.GOERLI], USDC[ChainId.GOERLI], BUSD[ChainId.GOERLI]],
  [ChainId.DEV_ETH]: [WNATIVE[ChainId.DEV_ETH], USDC[ChainId.DEV_ETH],PETA[ChainId.DEV_ETH]],
  [ChainId.DEV_ZK]: [WNATIVE[ChainId.DEV_ZK], USDC[ChainId.DEV_ZK],PETA[ChainId.DEV_ZK]],
  [ChainId.ZKSYNC]: [WNATIVE[ChainId.ZKSYNC], USDC[ChainId.ZKSYNC], PETA[ChainId.ZKSYNC], PTC_ZKSYNC, PTP_ZKSYNC],
  [ChainId.ZKSYNC_TESTNET]: [WNATIVE[ChainId.ZKSYNC_TESTNET], USDC[ChainId.ZKSYNC_TESTNET], PETA[ChainId.ZKSYNC_TESTNET], TEST_ZKSYNC_TESTNET],
  [ChainId.POLYGON]: [WNATIVE[ChainId.POLYGON], USDC[ChainId.POLYGON], PETA[ChainId.POLYGON], USDT_POLYGON, CBS_POLYGON,],
  [ChainId.POLYGON_TESTNET]: [WNATIVE[ChainId.POLYGON_TESTNET], USDC[ChainId.POLYGON_TESTNET], PETA[ChainId.POLYGON_TESTNET], CBS_POLYGON_TESTNET, TEST_POLYGON_TESTNET],
  [ChainId.BSC]: [
    PETA[ChainId.BSC],
    bscTokens.wbnb,
    bscTokens.busd,
    bscTokens.usdt,
    bscTokens.btcb,
    bscTokens.eth,
    bscTokens.usdc,
  ],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.usdc, bscTestnetTokens.peta],
  [ChainId.LINEA_TESTNET]: [WNATIVE[ChainId.LINEA_TESTNET], USDC[ChainId.LINEA_TESTNET], PETA[ChainId.LINEA_TESTNET]],
  [ChainId.FIL_TESTNET]: [WNATIVE[ChainId.FIL_TESTNET], USDC[ChainId.FIL_TESTNET], PETA[ChainId.FIL_TESTNET]],
  [ChainId.LINEA]: [WNATIVE[ChainId.LINEA], USDC[ChainId.LINEA], PETA[ChainId.LINEA]],
  [ChainId.FIL]: [WNATIVE[ChainId.FIL], PETA[ChainId.FIL]],
}

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  // [ChainId.BSC]: {
  //   [bscTokens.snfts.address]: [bscTokens.sfund],
  // },
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.BSC]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  // [ChainId.BSC]: {
  //   [bscTokens.axlusdc.address]: [bscTokens.usdt],
  // },
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.ETHEREUM]: [
    PETA[ChainId.ETHEREUM],
    USDC[ChainId.ETHEREUM],
    USDT[ChainId.ETHEREUM],
    WNATIVE[ChainId.ETHEREUM],
    WBTC_ETH,
  ],
  [ChainId.GOERLI]: [
    PETA[ChainId.GOERLI],
    USDC[ChainId.GOERLI],
    new WrappedTokenInfo({
      chainId: ChainId.GOERLI,
      address: WNATIVE[ChainId.GOERLI].address,
      decimals: WNATIVE[ChainId.GOERLI].decimals,
      symbol: WNATIVE[ChainId.GOERLI].symbol,
      name: WNATIVE[ChainId.GOERLI].name,
      logoURI: 'https://tokens.pancakeswap.finance/images/symbol/weth.png',
    }),
  ],
  [ChainId.DEV_ETH]: [
    PETA[ChainId.DEV_ETH],
    USDC[ChainId.DEV_ETH],
    USDT[ChainId.DEV_ETH],
    new WrappedTokenInfo({
      chainId: ChainId.DEV_ETH,
      address: WNATIVE[ChainId.DEV_ETH].address,
      decimals: WNATIVE[ChainId.DEV_ETH].decimals,
      symbol: WNATIVE[ChainId.DEV_ETH].symbol,
      name: WNATIVE[ChainId.DEV_ETH].name,
      logoURI: 'https://tokens.pancakeswap.finance/images/symbol/weth.png',
    }),
  ],
  [ChainId.DEV_ZK]: [
    PETA[ChainId.DEV_ZK],
    USDC[ChainId.DEV_ZK],
    new WrappedTokenInfo({
      chainId: ChainId.DEV_ZK,
      address: WNATIVE[ChainId.DEV_ZK].address,
      decimals: WNATIVE[ChainId.DEV_ZK].decimals,
      symbol: WNATIVE[ChainId.DEV_ZK].symbol,
      name: WNATIVE[ChainId.DEV_ZK].name,
      logoURI: 'https://tokens.pancakeswap.finance/images/symbol/weth.png',
    }),
  ],
  [ChainId.ZKSYNC]: [
    PETA[ChainId.ZKSYNC],
    USDC[ChainId.ZKSYNC],
    WNATIVE[ChainId.ZKSYNC],
    PTC_ZKSYNC,
    PTP_ZKSYNC,
  ],
  [ChainId.ZKSYNC_TESTNET]: [
    PETA[ChainId.ZKSYNC_TESTNET],
    USDC[ChainId.ZKSYNC_TESTNET],
    new WrappedTokenInfo({
      chainId: ChainId.ZKSYNC_TESTNET,
      address: WNATIVE[ChainId.ZKSYNC_TESTNET].address,
      decimals: WNATIVE[ChainId.ZKSYNC_TESTNET].decimals,
      symbol: WNATIVE[ChainId.ZKSYNC_TESTNET].symbol,
      name: WNATIVE[ChainId.ZKSYNC_TESTNET].name,
      logoURI: 'https://tokens.pancakeswap.finance/images/symbol/weth.png',
    }),
    TEST_ZKSYNC_TESTNET
  ],
  [ChainId.POLYGON_TESTNET]: [
    PETA[ChainId.POLYGON_TESTNET],
    USDC[ChainId.POLYGON_TESTNET],
    WNATIVE[ChainId.POLYGON_TESTNET],
    CBS_POLYGON_TESTNET,
    TEST_POLYGON_TESTNET
  ],
  [ChainId.POLYGON]: [
    PETA[ChainId.POLYGON],
    USDC[ChainId.POLYGON],
    WNATIVE[ChainId.POLYGON],
    USDT_POLYGON,
    CBS_POLYGON,
  ],
  [ChainId.BSC]: [bscTokens.usdt, bscTokens.usdc, PETA[ChainId.BSC], bscTokens.btcb, bscTokens.wbnb],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.usdc, bscTestnetTokens.peta],
  [ChainId.LINEA_TESTNET]: [WNATIVE[ChainId.LINEA_TESTNET], USDC[ChainId.LINEA_TESTNET], PETA[ChainId.LINEA_TESTNET]],
  [ChainId.FIL_TESTNET]: [WNATIVE[ChainId.FIL_TESTNET], USDC[ChainId.FIL_TESTNET], PETA[ChainId.FIL_TESTNET]],
  [ChainId.LINEA]: [WNATIVE[ChainId.LINEA], USDC[ChainId.LINEA], PETA[ChainId.LINEA]],
  [ChainId.FIL]: [WNATIVE[ChainId.FIL], PETA[ChainId.FIL]],
}

// used to construct the list of all pairs we consider by default in the frontend
//note:peta 默认显示的lp
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.ETHEREUM]: [
    PETA[ChainId.ETHEREUM],
    USDC[ChainId.ETHEREUM],
    WNATIVE[ChainId.ETHEREUM],
    USDT[ChainId.ETHEREUM],
    WBTC_ETH,
  ],
  [ChainId.GOERLI]: [PETA[ChainId.GOERLI], USDC[ChainId.GOERLI], WNATIVE[ChainId.GOERLI]],
  [ChainId.ZKSYNC]: [PETA[ChainId.ZKSYNC], USDC[ChainId.ZKSYNC], WNATIVE[ChainId.ZKSYNC], PTC_ZKSYNC, PTP_ZKSYNC,],
  [ChainId.DEV_ZK]: [PETA[ChainId.DEV_ZK], USDC[ChainId.DEV_ZK], WNATIVE[ChainId.DEV_ZK]],
  [ChainId.DEV_ETH]: [PETA[ChainId.DEV_ETH], USDC[ChainId.DEV_ETH], WNATIVE[ChainId.DEV_ETH]],
  [ChainId.ZKSYNC_TESTNET]: [PETA[ChainId.ZKSYNC_TESTNET], USDC[ChainId.ZKSYNC_TESTNET], WNATIVE[ChainId.ZKSYNC_TESTNET], TEST_ZKSYNC_TESTNET],
  [ChainId.BSC]: [bscTokens.wbnb, PETA[ChainId.BSC], bscTokens.usdt, bscTokens.usdc],
  [ChainId.POLYGON]: [PETA[ChainId.POLYGON], USDC[ChainId.POLYGON], WNATIVE[ChainId.POLYGON], USDT_POLYGON ,CBS_POLYGON],
  [ChainId.POLYGON_TESTNET]: [PETA[ChainId.POLYGON_TESTNET], USDC[ChainId.POLYGON_TESTNET], WNATIVE[ChainId.POLYGON_TESTNET], CBS_POLYGON_TESTNET, TEST_POLYGON_TESTNET],
  [ChainId.BSC_TESTNET]: [bscTestnetTokens.wbnb, bscTestnetTokens.usdc, bscTestnetTokens.peta],
  [ChainId.LINEA_TESTNET]: [WNATIVE[ChainId.LINEA_TESTNET], USDC[ChainId.LINEA_TESTNET], PETA[ChainId.LINEA_TESTNET]],
  [ChainId.FIL_TESTNET]: [WNATIVE[ChainId.FIL_TESTNET], USDC[ChainId.FIL_TESTNET], PETA[ChainId.FIL_TESTNET]],
  [ChainId.LINEA]: [WNATIVE[ChainId.LINEA], USDC[ChainId.LINEA], PETA[ChainId.LINEA]],
  [ChainId.FIL]: [WNATIVE[ChainId.FIL], PETA[ChainId.FIL]],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.ETHEREUM]: [
    [PETA[ChainId.ETHEREUM], USDC[ChainId.ETHEREUM]],
    [WNATIVE[ChainId.ETHEREUM], PETA[ChainId.ETHEREUM]],
    [PETA[ChainId.ETHEREUM], USDT[ChainId.ETHEREUM]],
  ],
  [ChainId.BSC]: [
    [bscTokens.wbnb, PETA[ChainId.BSC]],
    [bscTokens.usdc, PETA[ChainId.BSC]],
    [bscTokens.usdt, PETA[ChainId.BSC]],
  ],
}

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_TEN = JSBI.BigInt(10)

// one basis point
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)) // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BASE_FEE = new Percent(JSBI.BigInt(25), BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// BNB
export const DEFAULT_INPUT_CURRENCY = 'ETH'
// CAKE
export const DEFAULT_OUTPUT_CURRENCY = process.env.NEXT_PUBLIC_PETA_TOKEN_ADDRESS

// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

export const LIMIT_ORDERS_DOCS_URL = 'https://docs.pancakeswap.finance/products/pancakeswap-exchange/limit-orders'

export const EXCHANGE_PAGE_PATHS = ['/swap', '/limit-orders', 'liquidity', '/add', '/find', '/remove']
