import { BinanceWalletConnector } from '@pancakeswap/wagmi/connectors/binanceWallet'
import { BloctoConnector } from '@pancakeswap/wagmi/connectors/blocto'
import { TrustWalletConnector } from '@pancakeswap/wagmi/connectors/trustWallet'
import { bsc, bscTestnet, goerli, mainnet,zkSync,zkSyncTestnet, polygonMumbai,polygon } from 'wagmi/chains'
import { Chain, configureChains, createClient } from 'wagmi'
import memoize from 'lodash/memoize'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { LedgerConnector } from 'wagmi/connectors/ledger'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { SafeConnector } from './safeConnector'

const dev57 = {
  isTestnet: true,
  id: 9,
  name: 'devEth',
  network: 'devEth',
  nativeCurrency: {
    decimals: 18,
    name: 'devETH',
    symbol: 'dETH',
  },
  rpcUrls: {
    public: { http: ['https://eth-testnet.petians.com'] },
    default: { http: ['https://eth-testnet.petians.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'DevExplorer', url: 'https://120.55.117.95:38085' },
    default: { name: 'DevExplorer', url: 'https://120.55.117.95:38085' },
  },
  contracts: {
    multicall3: {
      address: '0x9F96DDf65E60cCD49B73340d8bd75448f33709DC',
      // blockCreated: 11_907_934,
    },
  }
} as Chain

const filTest = {
  isTestnet: true,
  id: 314159,
  name: 'Filecoin Calibration Testnet',
  network: 'filecoin-calibration',
  nativeCurrency: {
    decimals: 18,
    name: 'Filecoin',
    symbol: 'FIL',
  },
  rpcUrls: {
    public: { http: ["https://api.calibration.node.glif.io/rpc/v1"] },
    default: { http: ['https://api.calibration.node.glif.io/rpc/v1'] },
  },
  blockExplorers: {
    etherscan: { name: 'FilFox', url: 'https://calibration.filfox.info' },
    default: { name: 'FilFox', url: 'https://calibration.filfox.info' },
  },
  contracts: {
    multicall3: {
      address: '0x5B09E568DcFdE2375d373f3e163B66EA2f4E1ca2'
    },
  }
} as Chain

const fil = {
  isTestnet: false,
  id: 314,
  name: 'Filecoin Mainnet',
  network: 'filecoin-mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'filecoin',
    symbol: 'FIL',
  },
  rpcUrls: {
    public: { http: ["https://api.node.glif.io/rpc/v1"] },
    default: { http: ['https://api.node.glif.io/rpc/v1'] },
  },
  blockExplorers: {
    etherscan: { name: 'FilFox', url: 'https://filfox.info/en' },
    default: { name: 'FilFox', url: 'https://filfox.info/en' },
  },
  contracts: {
    multicall3: {
      address: '0x5375F7Af42efdF9d48f7BbB9102fd38c64CDCb9d'
    },
  }
} as Chain

const lineaTest = {
  isTestnet: true,
  id: 59140,
  name: 'Linea Goerli Testnet',
  network: 'linea-testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Linea Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ["https://rpc.goerli.linea.build"], webSocket: ["wss://rpc.goerli.linea.build"] },
    default: { http: ['https://rpc.goerli.linea.build'], webSocket: ["wss://rpc.goerli.linea.build"] },
  },
  blockExplorers: {
    etherscan: { name: 'BlockScout', url: 'https://explorer.goerli.linea.build' },
    default: { name: 'BlockScout', url: 'https://explorer.goerli.linea.build' },
  },
  contracts: {
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 498623,
    },
  }
} as Chain

const zk57 = {
  isTestnet: true,
  id: 270,
  name: 'devZk',
  network: 'devZk',
  nativeCurrency: {
    decimals: 18,
    name: 'devZkETH',
    symbol: 'dzETH',
  },
  rpcUrls: {
    public: { http: ['https://zk-testnet.petians.com'] },
    default: { http: ['https://zk-testnet.petians.com'] },
  },
  blockExplorers: {
    etherscan: { name: 'DevExplorer', url: 'https://120.55.117.95:38085' },
    default: { name: 'DevExplorer', url: 'https://120.55.117.95:38085' },
  },
  contracts: {
    multicall3: {
      address: '0x0b2b7F0f4A407F30A88919a13B601bf3C77c98B0',
    },
  }
} as Chain
const CHAINS_DEV = [zkSyncTestnet]
const CHAINS = process.env.NODE_ENV === 'development'? CHAINS_DEV : [fil, zkSync, polygon, bsc, mainnet]

const getNodeRealUrl = (networkName: string) => {
  let host = null

  switch (networkName) {
    case 'homestead':
      if (process.env.NEXT_PUBLIC_NODE_REAL_API_ETH) {
        host = `eth-mainnet.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODE_REAL_API_ETH}`
      }
      break
    case 'goerli':
      if (process.env.NEXT_PUBLIC_NODE_REAL_API_GOERLI) {
        // host = `eth-goerli.nodereal.io/v1/${process.env.NEXT_PUBLIC_NODE_REAL_API_GOERLI}`
        host = `flashy-morning-waterfall.ethereum-goerli.discover.quiknode.pro/${process.env.NEXT_PUBLIC_NODE_REAL_API_GOERLI}`
      }
      break
    // todo case 'zkSync':
    default:
      host = null
  }

  if (!host) {
    return null
  }

  const url = `https://${host}`
  return {
    http: url,
    webSocket: url.replace(/^http/i, 'wss').replace('.nodereal.io/v1', '.nodereal.io/ws/v1'),
  }
}

export const { provider, chains } = configureChains(CHAINS, [
  jsonRpcProvider({
    rpc: (chain) => {
      /* if (!!process.env.NEXT_PUBLIC_NODE_PRODUCTION && chain.id === bsc.id) {
        return { http: process.env.NEXT_PUBLIC_NODE_PRODUCTION }
      }
      if (process.env.NODE_ENV === 'test' && chain.id === mainnet.id) {
        return { http: 'https://cloudflare-eth.com' }
      } */
      return getNodeRealUrl(chain.network) || { http: chain.rpcUrls.default.http[0] }
    },
  }),
])

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

export const coinbaseConnector = new CoinbaseWalletConnector({
  chains,
  options: {
    appName: 'PancakeSwap',
    appLogoUrl: 'https://pancakeswap.com/logo.png',
  },
})

export const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    qrcode: true,
  },
})

export const walletConnectNoQrCodeConnector = new WalletConnectConnector({
  chains,
  options: {
    qrcode: false,
  },
})

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

const bloctoConnector = new BloctoConnector({
  chains,
  options: {
    defaultChainId: 56,
    appId: 'e2f2f0cd-3ceb-4dec-b293-bb555f2ed5af',
  },
})

const ledgerConnector = new LedgerConnector({
  chains,
})

export const bscConnector = new BinanceWalletConnector({ chains })

export const trustWalletConnector = new TrustWalletConnector({
  chains,
  options: {
    shimDisconnect: false,
    shimChainChangedDisconnect: true,
  },
})

export const client = createClient({
  autoConnect: false,
  provider,
  connectors: [
    new SafeConnector({ chains }),
    metaMaskConnector,
    injectedConnector,
    coinbaseConnector,
    walletConnectConnector,
    bscConnector,
    bloctoConnector,
    ledgerConnector,
    trustWalletConnector,
  ],
})

export const CHAIN_IDS = chains.map((c) => c.id)

export const isChainSupported = memoize((chainId: number) => CHAIN_IDS.includes(chainId))
export const isChainTestnet = memoize((chainId: number) => chains.find((c) => c.id === chainId)?.testnet)
